// Resets
.no-margin, .margin-0 { margin: 0; }

// Added by me!
img {
  max-width: 100%;
  display: block;
  height: auto;
  min-height: 0.01px;
}

// Headings
.heading {
  font-family: $base-heading-font-family;
  font-weight: 400;
  color: $soft-gold;
  margin: 0;
}
.heading-primary {
  color: $gold;
  text-align: center;
  /*
  &:after {
    content: '';
    width: 270px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    //background: url(/img/divider-gold.svg) 50% 50% no-repeat;
    height: 40px;
    margin-bottom: 20px;
  }
  */
}
.heading-secondary {
  color: $white;
  text-align: center;
  /*
  &:after {
    content: '';
    width: 270px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    //background: url(/img/divider-white.svg) 50% 50% no-repeat;
    height: 35px;
    margin-bottom: 20px;
  }
  */
}

// Blockquotes
blockquote {
  font-family: $base-font-family;
  font-weight: 400;
  font-style: italic;
}
cite {
  font-style: normal;
  font-weight: 700;
}

// Buttons
.button {
  color: $white;
  padding: 1rem 3rem;
  text-decoration: none;
  border: 1px solid transparent;
  background: $gold;
  transition: all 0.3s;
  font-weight: 700;
  display: inline-block;
  text-transform: uppercase;
  position: relative;
  &:hover {
    background: $soft-gold;
    color: $white;
  }
  &:active { top: 1px; }
}
.button-primary {
  border: 1px solid $black;
  @include background-rgba($black, .5);
  &:hover { @include background-rgba($black, 1); }
}
.button-secondary {
  border: 1px solid $white;
  @include background-rgba($white, .15);
  &:hover { @include background-rgba($white, 0); }
}

// Feature Box
.feature-box {
  padding: 20px 10px;
  color: $white;
  @include background-rgba($black, .8);
  @include breakpoint(m) {
    padding: 50px;
  }
}

// Pretty Select
.select {
  position: relative;
  display: block;
  &:before {
    content: '';
    border: 6px solid transparent;
    border-top-color: #676767;
    top: 50%;
    right: 10px;
    margin-top: -3px;
    pointer-events: none;
    position: absolute;
  }
  select {
    appearance: none;
    height: 36px;
    width: 100%;
    padding: 0 10px;
    line-height: normal;
    border: 1px solid $grey;
    background: $white;
    display: block;
  }
  select:focus { border-color: $gold; }
}

// Lists
.list-none {
  list-style: none;
  //padding: 0;
  //margin: 0;
}

// Images
.img-fluid { max-width: 100%; }

// Social Icons
.social {
  width: 16px;
  height: 25px;
  top: 7px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  display: block;
  position: relative;
  transition: all .2s;
}
.social-facebook {
  background-image: url(/img/facebook-white.svg);
  &:hover {
    background-image: url(/img/facebook-gold.svg);
  }
}
.social-twitter {
  background-image: url(/img/twitter-white.svg);
  &:hover {
    background-image: url(/img/twitter-gold.svg);
  }
}
.social-instagram {
  background-image: url(/img/instagram.svg);
  filter: invert(100%);
  &:hover {
    filter: invert(66%);
  }
}
.social-youtube {
  background-image: url(/img/youtube-white.svg);
  &:hover {
    background-image: url(/img/youtube-gold.svg);
  }
}
.social-google-plus {
  background-image: url(/img/google-plus-white.svg);
  &:hover {
    background-image: url(/img/google-plus-gold.svg);
  }
}

// Payment Icons
.payment {
  width: 40px;
  height: 40px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  display: block;
  position: relative;
}
.payment-mastercard { background-image: url(/img/mastercard-white.svg); }
.payment-visa { background-image: url(/img/visa-white.svg); }
.payment-american-express { background-image: url(/img/american-express-white.svg); }
.payment-paypal { background-image: url(/img/paypal-white.svg); }

// General Content
.content-block { background: $soft-grey; }
