// Hamburger Button for Mobile
.navigation-toggle-label {
  font-size: 16px;
  line-height: normal;
  border: 0;
  font-weight: 700;
  text-transform: uppercase;
  display: block;
  position: absolute;
  top: 50px;
  right: 20px;
  height: 20px;
  width: 25px;
  background: transparent;
  color: $white;
  cursor: pointer;
  @include breakpoint(m) {
    display: none;
  }
  &:before, &:after, .navigation-toggle-label-inner:before {
    content: '';
    width: 25px;
    height: 1px;
    background: $white;
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    transition: all .5s;
  }
  &:after { transform: translate3d(0, 7px, 0); }
  .navigation-toggle-label-inner:before { transform: translate3d(0, 14px, 0); }
}
// Hamburger Button - Active
.navigation-toggle-checkbox[type=checkbox]:checked + .navigation-toggle-label {
  &:before { transform: translate3d(0, 7px, 0) rotate(-225deg); }
  &:after { opacity: 0; }
  .navigation-toggle-label-inner:before { transform: translate3d(0, 7px, 0) rotate(45deg); }
}

// Navigation
.navigation {
  transition: all .2s;
  opacity: 0;
  max-height: 1px;
  overflow: hidden;  
  clear: both;
  @include breakpoint(m) {
    max-height: none;
    opacity: 1;
    margin-top: 0;
    clear: none;
  }
}
.navigation-toggle-checkbox[type=checkbox]:checked + .navigation-toggle-label + .navigation {
  opacity: 1;
  max-height: 400px;
}

// Navigation Menu
.navigation-menu {
  font-size: 18px;
  line-height: 24px;
  list-style: none;
  padding: 0;
  margin: 0;
  @include breakpoint(m) {
    font-size: 16px;
    line-height: 22px;
    list-style: none;
    margin: 0;
    padding: 0;
    display: inline-block;
  }
  .navigation-item {
    display: block;
    text-align: left;
    margin-top: 10px;
    @include breakpoint(m) {
      display: inline-block;
      margin: 0 5px;
    }
    & > a {
      font-family: $base-heading-font-family;
      margin: 0;
      display: block;
      color: $white;
      text-decoration: none;
      position: relative;
      transition: all .2s;
      @include breakpoint(m) {
        margin: 0;
        color: $white;
        padding: 50px 2px 0 2px;
        position: relative;
        &:before {
          transition: all .2s;
          width: 0px;
          height: 4px;
          background: $white;
          content: '';
          display: block;
          top: 0;
          left: 50%;
          position: absolute;
        }
      }
      @include breakpoint(l) {
        padding: 50px 15px 0 15px;
      }
      &:hover, &.active {
        color: $gold;
        @include breakpoint(m) {
          color: $white;
          &:before {
            left: 0;
            width: 100%;
          }
        }
      }
    }
  }
}

// Social Navigation Menu
.navigation-social {
  list-style: none;
  margin: 0;
  padding: 0;
  display: block;
  position: relative;
  margin-top: 15px;
  @include breakpoint(m) {
    list-style: none;
    margin: 0;
    padding: 0;
    display: inline-block;
    & > li {
      display: inline-block;
    }
  }
  & > li {
    display: inline-block;
  }
  &:before {
    content: '';
    width: 100%;
    height: 1px;
    @include background-rgba($white, .2);
    position: relative;
    top: 0;
    left: 0;
    display: block;
    @include breakpoint(m) {
      display: none;
    }
  }
}