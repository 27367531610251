// Header
.header {
  background: $darkest-grey;
  padding-bottom: 15px;
  position: relative;
  @include breakpoint(m) {
    background: transparent;
    opacity: .9;
  }
  &:after {
    content: '';
    width: 100%;
    height: 1px;
    @include background-rgba($white, .2);
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

// Logo
.logo {
  width: 226px;
  background: url(/img/logo_with_name.svg) 50% 50% no-repeat;
  background-size: contain;
  display: inline-block;
  transition: all .2s;
  opacity: 1;
  margin-top: 10px;
  &:hover { opacity: .8; }
}
