// Website Variables and Colors
//$base-font-family: "Open Sans", sans-serif;
$base-font-family: "Ubuntu", sans-serif;
$base-font-size: 16px;
$base-line-height: 28px;
$base-font-color: #494d4e;

// Headings
//$base-heading-font-family: "Yeseva One", cursive;
$base-heading-font-family: "Noto", sans-serif;
$base-h1-font-size: 48px;
$base-h1-line-height: 64px;
$base-h2-font-size: 36px;
$base-h2-line-height: 40px;
$base-h3-font-size: 22px;
$base-h3-line-height: 28px;
$base-h4-font-size: 18px;
$base-h4-line-height: 24px;
$base-h5-font-size: 16px;
$base-h5-line-height: 22px;
$base-h6-font-size: 14px;
$base-h6-line-height: 20px;

// Colours
$gold: #c90;
$soft-gold: #cd9f0b;
$special-gold: #b6a46c;
$copy-grey: #494d4e;
$soft-grey: #f6f6f6;
$darkest-grey: #212121;
$black: #000;
$smoke: #f1f1f1;
$mid-grey: #999;
$grey: #ccc;
$white: #fff;
