// Reviews Block
.reviews-block {
  //background: url(/img/reviews-bg.jpg) 0 50% no-repeat $darkest-grey;
  background: $darkest-grey;
  background-size: cover;
}

// Reviews Block Animations
.reviews-block.active {
  @include animate;
  animation-delay: 0.2s;
  @extend .fade-in;
  .feature-box {
    @include animate;
    animation-delay: 0.4s;
    @extend .fade-in;
    h2 {
      @include animate;
      animation-delay: 0.6s;
      @extend .fade-in-down;
    }
    blockquote {
      @include animate;
      animation-delay: 0.6s;
      @extend .fade-in-up;
    }
  }
}
